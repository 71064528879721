<template>
  <b-modal
    v-model="loader"
    id="base__loader-modal"
    size="sm"
    centered
    no-fade
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
  >
    <template v-slot:default>
      <div class="text-center">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div v-if="loaderMsgText" class="text-uppercase mt-4 text-white">
          {{ $t(loaderMsgText, loaderMsgParams) }}
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  computed: {
    loader: {
      get() {
        return this.$store.getters["loader/isActive"];
      },
      set() {
        this.$store.commit("loader/RESET");
      }
    },
    loaderMsgText() {
      return this.$store.getters["loader/getMessageText"];
    },
    loaderMsgParams() {
      return this.$store.getters["loader/getMessageParams"];
    }
  }
};
</script>

<style>
#base__loader-modal .modal-content {
  width: auto !important;
  margin: auto;
  background-color: transparent;
  border: unset;
}
</style>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
