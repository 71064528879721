<template>
  <b-modal
    v-model="state"
    content-class="rounded-0"
    ok-title="Ok"
    ok-variant="secondary"
    ok-only
  >
    <template v-slot:modal-title>
      <span v-html="$t(titleText, titleParams)"></span>
    </template>
    <p v-html="$t(messageText, messageParams)"></p>
  </b-modal>
</template>

<script>
export default {
  computed: {
    state: {
      get() {
        return this.$store.getters["alert/isActive"];
      },
      set() {
        this.$store.commit("alert/RESET");
      }
    },
    type() {
      return this.$store.getters["alert/getType"];
    },
    titleText() {
      return this.$store.getters["alert/getTitleText"];
    },
    titleParams() {
      return this.$store.getters["alert/getTitleParams"];
    },
    messageText() {
      return this.$store.getters["alert/getMessageText"];
    },
    messageParams() {
      return this.$store.getters["alert/getMessageParams"];
    }
  }
};
</script>

<style lang="scss" scoped>
.success {
  background-color: #c1f4cd;
  border-color: #21ba45;
}
.warning {
  background-color: #f9e09b;
  border-color: #f2c037;
}
.error {
  background-color: #ffc0c6;
  border-color: #c10015;
}
</style>
