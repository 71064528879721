<template>
  <b-modal
    v-model="state"
    size="lg"
    content-class="rounded-0"
    footer-class="border-top-0"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @ok="onApprove"
    @cancel="onCancel"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0">
        <span v-html="$t(titleText, titleParams)"></span>
      </h5>
    </template>
    <template v-slot:default>
      <p v-html="$t(messageText, messageParams)"></p>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="link"
        class="text-dark text-decoration-none"
        @click="cancel"
        >{{ $t(cancelBtnLabel) }}</b-button
      >
      <b-button class="text-white" :variant="confirmBtnColor" @click="ok">
        {{ $t(confirmBtnLabel) }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  methods: {
    onCancel() {
      this.$store.dispatch("confirm/cancelAction");
    },
    onApprove() {
      this.$store.dispatch("confirm/confirmAction");
    }
  },
  computed: {
    state: {
      get() {
        return this.$store.getters["confirm/isActive"];
      },
      set(active) {
        this.$store.commit("confirm/SET_ACTIVE", active);
      }
    },
    titleText() {
      return this.$store.getters["confirm/getTitleText"];
    },
    titleParams() {
      return this.$store.getters["confirm/getTitleParams"];
    },
    messageText() {
      return this.$store.getters["confirm/getMessageText"];
    },
    messageParams() {
      return this.$store.getters["confirm/getMessageParams"];
    },
    cancelBtnLabel() {
      return this.$store.getters["confirm/getCancelBtnLabel"];
    },
    confirmBtnLabel() {
      return this.$store.getters["confirm/getConfirmBtnLabel"];
    },
    type() {
      return this.$store.getters["confirm/getType"];
    },
    confirmBtnColor() {
      let colorType = null;
      switch (this.type) {
        case "DELETE":
          colorType = "orange";
          break;
        case "PROCEED":
        default:
          colorType = "primary";
          break;
      }

      return colorType;
    }
  }
};
</script>

<style scoped></style>
